<template>
    <div v-html="html"></div>
</template>
<script>
import api from "../../api/ztb";
import { Dialog } from 'vant';
// import { dir } from "console";
export default {
    data() {
        return {
            html: '',
        }
    },
    // created() {
    //     this.fetchVideoPay();
    // },
    methods: {
        fetchVideoPay() {
            // const newTab = window.open();
            let param = {
                orderJg: this.$route.query.jg,
                price: this.$route.query.price,
                userId: this.$route.query.userId,
                priceName: this.$route.query.priceName,
                // shopName: this.$route.query.timeLimit
                appId: this.$route.query.appId,
            };
            api.postWachPay(param).then(res => {
                // this.html = res.data;
                // let data = JSON.parse(res.data)
                // window.location.href=data.h5_url;

                // console.log(res)
                // console.log(res.data)
                // alert(res.data['h5_url'])
                localStorage.setItem("order_code", res.data['out_trade_no']) //缓存order_code 微信订单编号，当支付完了后跳转的返回页面（返回页面地址写在接口）的时候拿出order_code再请求平台订单更新接口

                //window.location.href=res.data['h5_url'];
                window.location.replace(res.data['h5_url'])


                // if(data.summary == "支付成功"){
                //     window.location.href='https://weixin.qq.com/';
                // }
            })
        },

        //发送绑定
        submitFormUser(params) {
            const newTab = window.open();
            api.postAlipay(params).then((res) => {
                console.table(res);
                if (res.status == 200) {
                    // this.$toast("绑定成功！")
                    console.log("=============00000000000000000000000");
                    console.log(res.data)
                    // window.open(innerHtml(res.data), "_blank");
                    // this.sqfk()

                    // window.open(res.data.innerHTML, '_ blank')

                    const div = document.createElement('div');
                    div.innerHTML = res.data; // html code
                    document.body.appendChild(div);
                    document.forms.alipaysubmit.submit();
                } else {
                    console.log("=============33333333333333333333333");
                }
            });
        },
    },
    mounted() {
        this.fetchVideoPay()
    }
}
</script>
